import React, { useState } from "react";
import { IoIosCloseCircle, IoIosUndo } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

import Modal from "../../../components/Modals";
import api from "../../../services/api";

const ModalCancel = ({
    actionModal,
    openModal, 
    entity, 
    setLoadingChangeStatus,
    setLoadingCancell,
    setQuoteStatus,
    setDisabledForm
}) => {
    const [loading, setLoading] = useState(false);
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Cancelar o orçamento - {entity?.name}
                    </h5>
                </div>
            }>
            <p className="text-center">Esta ação é permanente, deseja prosseguir?</p>
            <button
                className="mb-4 ml-2 bg-C4 text-white py-2 px-5 rounded-md float-left mt-10 flex items-center"
                onClick={() => {
                    actionModal();
                    setLoadingChangeStatus(false);
                }}>
                <IoIosUndo size={"1em"} />
                <span className="text-lg ml-1">Voltar</span>
            </button>
            <button
                className="mb-4 bg-red text-white py-2 px-5 rounded-md float-right mt-10 flex items-center"
                onClick={() => {
                    setLoading(true);
                    api.post(
                        "quote/" + entity?.identifier + "/change-status",
                        {
                            status: "cancel",
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    )
                        .then(response => {
                            setLoadingChangeStatus(false);
                            setLoadingCancell(false);
                            setQuoteStatus({
                                'status': response?.data?.status,
                                'date': response?.data?.closedAtFormatted
                            });
                            setLoadingCancell(false);
                            toast.success("Cotação " + entity?.name + " cancelada com sucesso");
                            setLoading(false);
                            setDisabledForm(true);
                            actionModal();
                        })
                        .catch(error => {
                            setLoading(false);
                            setLoadingChangeStatus(false);
                            setLoadingCancell(false);
                        });
                }}>
                <IoIosCloseCircle size={"1em"} />
                <span className="text-lg ml-1">Remover {loading && <ClipLoader size="13" color="white" />}</span>
            </button>
        </Modal>
    );
};
export default ModalCancel;